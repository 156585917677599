import { Chip } from '@mui/material';
import {
  GridActionsCellItem,
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import { filterOperators, dateFilterOperators, numberFilterOperators } from '../../../../constants';
import { baseCell } from '../cells/base-cell';
import { currencyCell } from '../cells/currency-cell';
import PriorityHigh from '@mui/icons-material/PriorityHigh';
import EditIcon from '@mui/icons-material/Edit';

export function AggregatedUnapprovedMovementColumns(handleEdit?: any): GridColDef[] {
  const { t } = useTranslation();
  const translationPrefix = 'entities.movement';

  const columns: GridColumns | any[] = [
    {
      field: '',
      flex: 50,
      headerName: 'Actions',
      align: 'right',
      type: 'actions',
      getActions: (params: GridRowParams) => {
        return [
          <GridActionsCellItem
            key={1}
            label="Modifica"
            icon={<EditIcon />}
            onClick={() => {
              handleEdit(params.row);
            }}
          />
        ];
      }
    },
    {
      field: 'expected_equal',
      headerName: '',
      width: 50,
      renderCell: (params: GridRenderCellParams<string>) =>
        params.row.remaining_quantity &&
        !params.value && (
          <Chip sx={{ background: 'transparent' }} icon={<PriorityHigh color="warning" />} />
        )
    },
    {
      field: 'total_quantity',
      width: 50,
      headerName: t(`${translationPrefix}.moved_quantity`),
      type: 'number',
      filterOperators: numberFilterOperators,
      renderCell: baseCell
    },
    {
      field: 'remaining_quantity',
      headerName: t(`entities.order_line.remaining_quantity`),
      type: 'number',
      filterOperators: numberFilterOperators,
      renderCell: baseCell
    },
    {
      field: 'product',
      headerName: t(`global.product`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      filterOperators,
      width: 200,
      renderCell: baseCell
    },
    {
      field: 'product_notes',
      headerName: t(`entities.product.product_notes`),
      filterable: false,
      sortable: false,
      width: 200,
      renderCell: baseCell
    },
    {
      field: 'order_notes',
      headerName: t(`entities.product.purchase-request-notes`),
      filterable: false,
      sortable: false,
      width: 100,
      renderCell: baseCell
    },
    {
      field: 'contract',
      headerName: t(`global.contract`),
      filterOperators,
      width: 75,
      renderCell: baseCell
    },
    {
      field: 'supplier',
      headerName: t(`global.supplier`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'timestamp',
      headerName: t(`${translationPrefix}.timestamp`),
      filterOperators: dateFilterOperators,
      width: 125,
      renderCell: baseCell
    },
    {
      field: 'price',
      headerName: t(`${translationPrefix}.price`),
      filterable: false,
      sortable: false,
      type: 'number',
      width: 100,
      renderCell: currencyCell
    },
    {
      field: 'unit_price',
      headerName: t(`${translationPrefix}.unit_price`),
      filterOperators,
      type: 'number',
      width: 100,
      renderCell: currencyCell
    },
    {
      field: 'warehouse',
      headerName: t(`global.warehouse`),
      filterOperators,
      width: 200,
      renderCell: baseCell
    },

    {
      field: 'movement_type',
      headerName: t(`${translationPrefix}.type`),
      width: 50,
      renderCell: baseCell
    },
    {
      field: 'order_code',
      headerName: t(`entities.movement.order_code`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'transport_document_code',
      headerName: t(`${translationPrefix}.transport_document`),
      filterOperators,
      width: 200,
      renderCell: baseCell
    },
    {
      field: 'transport_document_date',
      headerName: t(`${translationPrefix}.transport_document_date`),
      filterOperators: dateFilterOperators,
      width: 100,
      renderCell: baseCell
    },
    {
      field: 'created_by',
      headerName: t(`${translationPrefix}.created_by`),
      filterOperators,
      width: 100,
      renderCell: baseCell
    }
  ];

  return columns;
}
